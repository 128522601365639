import "../bootstrap";
import "../tinyslider";
var sliderContainer = document.querySelector(".item-slider");
if (sliderContainer) {
    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 20,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#industry-control",
        responsive: {
            320: {
                items: 1,
            },
            430: {
                items: 2,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 1.5,
            },
            1080: {
                items: 1.5,
            },
            1280: {
                items: 2.2,
            },
            1440: {
                items: 2.5,
            },
            1660: {
                items: 2.8,
            },
        },
    });
}

var sliderTestimonials = document.querySelector(".testimonial-slider");
if (sliderTestimonials) {
    var slider = tns({
        container: sliderTestimonials,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        gutter: 16,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#testimonial-control",
        responsive: {
            320: {
                items: 1,
            },
            768: {
                items: 1,
            },
            1024: {
                items: 2,
            },
            1280: {
                items: 2,
            },
            1441: {
                items: 3,
            },
        },
    });
}

var sliderContainer = document.querySelector(".my-slider");

if (window.innerWidth >= 600 && sliderContainer) {
    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: true,
        nav: false,
        controlsPosition: "bottom",
        controlsContainer: "#custom-control",
        items: 2.5,
        responsive: {
            1440: {
                items: 2.5,
            },
        },
    });
    sliderContainer.addEventListener("wheel", function (event) {
        if (event.deltaY < 0) {
            slider.goTo("prev");
        } else {
            slider.goTo("next");
        }
    });
}

var sliderContainers = document.querySelectorAll(".tab-pane");

sliderContainers.forEach(function (container) {
    var sliderContainer = container.querySelector(".ppc-slider");

    var slider = tns({
        container: sliderContainer,
        autoplay: false,
        center: false,
        mouseDrag: true,
        loop: false,
        arrowKeys: false,
        gutter: 20,
        nav: true,
        navPosition: "bottom",
        controls: false,
        responsive: {
            320: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 2,
            },
            1280: {
                items: 3,
            },
        },
    });
});

window.onload = function () {
    var dropdowns = document.querySelectorAll(".nav-item.dropdown");
    var currentTimeout = null;
    var hoveredDropdown = null;

    dropdowns.forEach(function (dropdown) {
        var dropdownMenu = dropdown.querySelector(".dropdown-menu");

        dropdown.addEventListener("mouseenter", function () {
            clearTimeout(currentTimeout);

            if (hoveredDropdown && hoveredDropdown !== dropdown) {
                currentTimeout = setTimeout(function () {
                    closeAllDropdowns();
                    showDropdownWithOverlay(dropdown, dropdownMenu);
                    dropdown.classList.add("hovered");
                    hoveredDropdown = dropdown;
                }, 300);
            } else {
                closeAllDropdowns();
                showDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.add("hovered");
                hoveredDropdown = dropdown;
            }
        });

        dropdown.addEventListener("mouseleave", function () {
            clearTimeout(currentTimeout);
            currentTimeout = setTimeout(function () {
                hideDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.remove("hovered");
                if (!isAnyDropdownHovered()) {
                    hoveredDropdown = null;
                }
            }, 300);
        });

        dropdownMenu.addEventListener("mouseenter", function () {
            clearTimeout(currentTimeout); // Cancel delay when entering the dropdown menu
        });

        dropdownMenu.addEventListener("mouseleave", function () {
            currentTimeout = setTimeout(function () {
                hideDropdownWithOverlay(dropdown, dropdownMenu);
                dropdown.classList.remove("hovered");
                if (!isAnyDropdownHovered()) {
                    hoveredDropdown = null;
                }
            }, 200);
        });

        dropdownMenu.addEventListener("click", function () {
            hideDropdownWithOverlay(dropdown, dropdownMenu);
        });

        var dropdownLinks = dropdownMenu.querySelectorAll("a");
        dropdownLinks.forEach(function (link) {
            link.addEventListener("click", function () {
                dropdown.querySelector(".drop-overlay").style.display = "none";
                dropdownMenu.style.display = "none";
            });
        });
    });

    function closeAllDropdowns() {
        dropdowns.forEach(function (dropdown) {
            hideDropdownWithOverlay(
                dropdown,
                dropdown.querySelector(".dropdown-menu")
            );
        });
    }

    function showDropdownWithOverlay(dropdown, dropdownMenu) {
        dropdownMenu.style.display = "block";
        let overlay = dropdown.querySelector(".drop-overlay");
        if (!overlay) {
            overlay = document.createElement("div");
            overlay.className = "drop-overlay";
            dropdown.appendChild(overlay);
            overlay.addEventListener("mouseenter", function () {
                dropdownMenu.style.display = "none";
                this.style.display = "none";
            });

            overlay.addEventListener("mouseleave", function () {
                setTimeout(function () {
                    hideDropdownWithOverlay(dropdown, dropdownMenu);
                }, 200);
            });
        }
        overlay.style.display = "block";
    }

    function hideDropdownWithOverlay(dropdown, dropdownMenu) {
        let overlay = dropdown.querySelector(".drop-overlay");
        if (
            !dropdown.matches(":hover") &&
            !dropdownMenu.matches(":hover") &&
            (!overlay || !overlay.matches(":hover"))
        ) {
            dropdownMenu.style.display = "none";
            if (overlay) {
                overlay.style.display = "none";
            }
        }
    }

    function isAnyDropdownHovered() {
        return Array.from(dropdowns).some(function (dropdown) {
            return dropdown.classList.contains("hovered");
        });
    }
};

const foundedYear = 2017;
const currentYear = new Date().getFullYear();
const yearsInBusiness = currentYear - foundedYear;

document.querySelectorAll(".established").forEach((element) => {
    element.textContent = yearsInBusiness + "+";
});

function setEqualHeight() {
    const items = document.querySelectorAll(".ppc-slider-item");
    let maxHeight = 0;

    items.forEach((item) => {
        const itemHeight = item.offsetHeight;
        if (itemHeight > maxHeight) {
            maxHeight = itemHeight;
        }
    });

    items.forEach((item) => {
        item.style.height = maxHeight + "px";
    });
}

window.addEventListener("load", setEqualHeight);
window.addEventListener("resize", setEqualHeight);
